.html-container li {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    padding-top: 2px;
    padding-bottom: 3px;

}

.html-container h3 {
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 48px;
    padding-top: 15px;

}

.html-container a {
    color: #4e4949;
}


.html-container h1 h2 h3 h4 h5 h6 li p strong {
    color: #000;
    font-family: 'Poppins';
}


.ql-video {
    width: 100%;
    height: 300px;
    border-radius: 5px
}
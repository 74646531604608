html,
body,
#root,
.app,
.content {
  /* width: 100%; */
  /* user-select: none !important; */
  background: white;
  /* overflow-x: hidden;
  overflow-y: hidden; */
}

/* Import Lexend font from Google Fonts */
@import url("https://fonts.googleapis.com/css2?family=Lexend&display=swap");

/* Use Lexend font in your CSS */
body {
  font-family: "Lexend", sans-serif;
}

.app {
  display: flex;
  position: relative;
  overflow-x: hidden;
  /* overflow-y: hidden; */
  height: 100vh;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: grey;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.RemoveStylesFromATags {
  text-decoration: none;
  color: inherit;
  display: block;
  width: auto;
  height: auto;
}

@keyframes pulsate {
  0% {
    box-shadow: 0px 0px 10px #242f8c;
  }
  50% {
    box-shadow: 0px 0px 25px #242f8c;
  }
  100% {
    box-shadow: 0px 0px 10px #242f8c;
  }
}

.pulsating-button {
  /* animation: pulsate 1.4s infinite ease-in-out; */
}

.retuneIframeStyles {
  border-radius: 10px;
}

@media screen and (max-width: 900px) {
  .retuneIframeStyles {
    border-radius: 0px;
  }
}

iframe#webpack-dev-server-client-overlay {
  display: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px white inset !important;
  border-radius: 16px !important;
}

input:-webkit-autofill {
  -webkit-text-fill-color: black !important;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  caret-color: black !important; /* Set the cursor color */
}
@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,800);

.resetPassBody {
  font-family: "Montserrat", sans-serif;
  background-image: url("assets/createAccountHeader.svg");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-color: blue;
}

@media (max-width: 767px) {
  .video-react-player {
    height: auto !important;
  }
}

@media (min-width: 768px) {
  .video-react-player {
    height: 465px !important;
  }
}
